//@flow
import React from 'react';
import './candidate.scss';

type Props = {
  interviewerName: string,
  date: string,
  startTime: string,
  endTime: string,
};
const Interview = (props: Props) => {
  return (
    <div className="tbody">
      <div
        className="row"
        style={{ backgroundColor: 'rgba(0, 0, 0, 0.05)', borderRadius: '1.5rem', height: '60px' }}
      >
        <div className="columnLarge">{props.interviewerName}</div>
        <div className="columnMedium">{props.interviewerSlackId}</div>
        <div className="columnMedium">{props.date}</div>
        <div className="columnSmall">{props.startTime}</div>
        <div className="columnSmall">{props.endTime}</div>
      </div>
    </div>
  );
};

export default Interview;
