//@flow
import React from 'react';
// import { connect } from 'react-redux';
// import { getAllPricings } from '../../redux/actions';
import Pricing from './Pricing';
import './Pricing.scss';

// class Pricings extends Component {
//   componentDidMount() {
//     const { getAllPricings } = this.props;
//     getAllPricings();
//   }

//   render() {
const pricings = [
  {
    name: 'Software Engineering Track',
    discount: 'USD 50 if an S30 student refers',
    postJobPayableAmount: '0',
    totalAmountPayableINR: 'Rs 68000',
    totalAmountPayable: 'USD 799',
    dueDate: 'Available',
  },
  {
    name: 'Software Engineering Track(Inagural Cohort)- For students enrolled in B.tech in India',
    discount: 'Flat fee',
    postJobPayableAmount: '0',
    totalAmountPayableINR: 'Rs 25000',
    // totalAmountPayable: 'USD 399',
    dueDate: 'Available',
  },
  {
    name: 'Data Science/Engineer Cohort',
    discount: 'USD 50 if an S30 student refers',
    postJobPayableAmount: '0',
    totalAmountPayableINR: 'Rs 60000',
    totalAmountPayable: 'USD 699',
    dueDate: 'Available',
  },
  {
    name: 'System Design Cohort',
    discount: 'NA',
    postJobPayableAmount: '0',
    totalAmountPayableINR: 'Rs 25000',
    totalAmountPayable: 'USD 299',
    dueDate: 'Available',
  },
  // {
  //   name: 'S30 Projects Cohort',
  //   discount: 'NA',
  //   postJobPayableAmount: '0',
  //   totalAmountPayableINR: 'Rs 17500',
  //   totalAmountPayable: 'USD 199',
  //   dueDate: 'Not Available',
  // },
];

type Props = {
  pricings: Array<Object>,
};
const Pricings = (props: Props) => {
  return (
    <div className="mainBody">
      <div className="success-stories">
        <div className="testimonial-container" style={{ flexWrap: 'wrap' }}>
          {pricings.map((plan, index) => {
            return (
              <Pricing
                key={index}
                discount={plan.discount}
                postJobPayableAmount={plan.postJobPayableAmount}
                totalAmountPayable={plan.totalAmountPayable}
                totalAmountPayableINR={plan.totalAmountPayableINR}
                name={plan.name}
                dueDate={plan.dueDate}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Pricings;
// const mapStateToProps = state => ({
//   pricings: state.pricingState.allPricings,
//   error: state.pricingState.error,
// });

// export default connect(
//   mapStateToProps,
//   { getAllPricings },
// )(Pricings);
