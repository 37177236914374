//@flow
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { updateCandidate } from '../../redux/actions';
// $FlowFixMe.
import { withStyles } from '@material-ui/core/styles';
import Interview from './Interview';
import './candidate.scss';
// $FlowFixMe.
import moment from 'moment';
import Snackbar from '@material-ui/core/Snackbar';

import ROOT_URL from '../../config/baseUrl';
import axios from 'axios';

//Socket
// import Socket from 'socket.io-client';

const styles = {
  submit: {
    backgroundColor: '#009688',
    color: 'white',
    fontSize: 16,
    margin: 30,
  },
  root: {
    backgroundColor: 'red',
  },
  container: { display: 'flex', flexDirection: 'column', margin: '5rem', width: '40rem' },
  resize: {
    fontSize: 15,
  },
  myHelperTextStyle: {
    fontSize: 12,
    color: 'Red',
  },
};
type Props = { user: Object, getUpdatedUser: Function };

class CandidateDashboard extends Component<Props> {
  constructor() {
    super();
    this.state = {
      submitting: false,
      studentRankings: [],
    };
  }
  async componentDidMount() {
    const apiurl = `${ROOT_URL}/api/classrank`;
    const token = localStorage.getItem('token');
    const headers = {
      headers: {
        Authorization: token,
      },
    };
    try {
      const ranks = await axios.get(apiurl, headers);
      this.setState({ studentRankings: ranks.data });
    } catch (error) {
      console.log(error);
    }
  }
  markAvailability = async (id, candidateInfo) => {
    const apiurl = `${ROOT_URL}/api/candidate/${id}`;
    const token = localStorage.getItem('token');
    const headers = {
      headers: {
        Authorization: token,
      },
    };
    try {
      if (!candidateInfo.isAvailableForInterview) {
        if (
          confirm(
            `Missing interviews redcuces your chances to get refferals. Please press ok if you still wish to mark unavailable for mock interview`,
          )
        ) {
          this.setState({ submitting: true });
          await axios.put(apiurl, candidateInfo, headers);
          setTimeout(() => {
            this.setState({ submitting: false });
          }, 1000);
          alert(
            'Request completed. Make sure to mark yourself available if you wish to interview in any subsequent week',
          );
        }
      } else {
        this.setState({ submitting: true });
        await axios.put(apiurl, candidateInfo, headers);
        setTimeout(() => {
          this.setState({ submitting: false });
        }, 1000);
        alert(
          'Request completed. Your interview will be scheduled on weekend if marked available before Friday 11pm pacific',
        );
      }
      window.location.reload();
    } catch (error) {
      console.log(error);
    }
  };
  requestAlumnInterview = async (e, interviewTypeCode) => {
    e.preventDefault();
    const apiurl = `${ROOT_URL}/api/requestAlumnInterview`;
    const token = localStorage.getItem('token');
    const headers = {
      headers: {
        Authorization: token,
      },
    };
    try {
      if (confirm(`Please confirm if you wish to request for alumni mock interview`)) {
        this.setState({ submitting: true });
        await axios.put(apiurl, { interviewTypeCode }, headers);
        setTimeout(() => {
          this.setState({ submitting: false });
        }, 1000);
        alert('Requested Interview, Kindly check slack id for schedule on Saturday Evening');
      }
    } catch (error) {
      console.log('error in requesting interview', error);
    }
  };
  render() {
    const { submitting, studentRankings } = this.state;
    const { user, candidate, classes } = this.props;
    const {
      _id,
      billingLocation,
      isBlackListed,
      isAvailableForInterview,
      isSystemDesignAuthorized,
    } = candidate;
    var date = new Date();
    const monthsdifference = moment(new Date(date)).diff(
      new Date(candidate.createdAt),
      'months',
      true,
    );
    const isAlum = monthsdifference >= 4 ? true : false;
    const isFresher = monthsdifference < 4 ? true : false;
    let timeslot = {};
    let userId = {};
    if (
      user.candidateId != undefined &&
      user.candidateId.assignedInterviewer != null &&
      user.candidateId.isScheduled
    ) {
      timeslot = user.candidateId.assignedInterviewer.timeslot;
      userId = user.candidateId.assignedInterviewer.interviewerId.userId;
    }
    return (
      <div className="candidateDashBoard">
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          open={submitting}
          autoHideDuration={6000}
          ContentProps={{
            classes: { root: classes.submit },
          }}
          message={<p>Submitting...</p>}
        />
        <h3 className="title">Student Success Panel</h3>
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
          <div
            className="table"
            style={{
              width: '55%',
              display: 'flex',
              alignItems: 'flex-start',
              justifyContent: 'flex-start',
            }}
          >
            <p></p>
            {isFresher && (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  marginLeft: '40px',
                  alignItems: 'flex-start',
                }}
              >
                <p style={{ fontSize: '14px', margin: '8px' }}>
                  1. How to submit Precourse, Pull Requests and resolving common errors?
                  <img
                    src={require('../../static/youtube.png')}
                    alt="Youtube Icon"
                    className="icon-small"
                    style={{ marginLeft: '1rem' }}
                    onClick={() =>
                      window.open(
                        `https://www.loom.com/share/1c134467fd55468c9ef5e752240915a8?sid=2dac3bbb-4031-40fe-b2a8-c143596a92dd`,
                        '_blank',
                      )
                    }
                  />
                </p>

                <p style={{ fontSize: '14px', margin: '8px' }}>
                  2. How to get expert feedback on Resume and LinkedIn from S30?
                  <img
                    src={require('../../static/youtube.png')}
                    alt="Youtube Icon"
                    className="icon-small"
                    style={{ marginLeft: '1rem' }}
                    onClick={() =>
                      window.open(
                        `https://www.loom.com/share/7e8f649836ee492ea3ffe55e4f323462?sid=6815df0b-6372-4c32-b366-bafeb151ad93`,
                        '_blank',
                      )
                    }
                  />
                </p>
                <p style={{ fontSize: '14px', margin: '8px' }}>
                  3. How to attend Live Lectures and access recorded videos?
                  <img
                    src={require('../../static/youtube.png')}
                    alt="Youtube Icon"
                    className="icon-small"
                    style={{ marginLeft: '1rem' }}
                    onClick={() =>
                      window.open(
                        `https://www.loom.com/share/84088494678a49f9b968bbe732ace2a1?sid=2d33f347-2ab0-45c6-9fdd-e581e28d1468`,
                        '_blank',
                      )
                    }
                  />
                </p>
                <p style={{ fontSize: '14px', margin: '8px' }}>
                  4. Everything about Mock Interviews
                  <></>
                  <img
                    src={require('../../static/youtube.png')}
                    alt="Youtube Icon"
                    className="icon-small"
                    style={{ marginLeft: '1rem' }}
                    onClick={() =>
                      window.open(
                        `https://www.loom.com/share/eaf560273071481db59da77dcc30031f?sid=c1d3cc0a-befa-4d08-bd9a-658ce45a5c09`,
                        '_blank',
                      )
                    }
                  />
                </p>
                <p style={{ fontSize: '14px', margin: '8px' }}>
                  5. How/When to pay fee?
                  <img
                    src={require('../../static/youtube.png')}
                    alt="Youtube Icon"
                    className="icon-small"
                    style={{ marginLeft: '1rem' }}
                    onClick={() =>
                      window.open(
                        `https://www.loom.com/share/802c9a7a7b33424c85f337f137f90dac?sid=24afc228-91b4-48d1-8288-81237c48724a`,
                        '_blank',
                      )
                    }
                  />
                </p>
                <p style={{ fontSize: '14px', margin: '8px' }}>
                  6. Interview Experiences and Questions Asked By Company
                  <img
                    src={require('../../static/youtube.png')}
                    alt="Youtube Icon"
                    className="icon-small"
                    style={{ marginLeft: '1rem' }}
                    onClick={() =>
                      window.open(
                        `https://www.loom.com/share/82dd14a456584b6bb5a8727334704986?sid=8b8f8d0d-1a67-4e7a-8a6c-76ad1363683a`,
                        '_blank',
                      )
                    }
                  />
                </p>
                <p style={{ fontSize: '14px', margin: '8px' }}>
                  7. How to get Referrals from S30?
                  <img
                    src={require('../../static/youtube.png')}
                    alt="Youtube Icon"
                    className="icon-small"
                    style={{ marginLeft: '1rem' }}
                    onClick={() =>
                      window.open(
                        `https://www.loom.com/share/1663c38e7121444aa7e36bde04341554?sid=6e281929-6674-4bb5-9a5f-59229f3f8064`,
                        '_blank',
                      )
                    }
                  />
                </p>
              </div>
            )}
            {isAlum && (
              <>
                <p style={{ fontSize: '14px', margin: '8px' }}>
                  1. How to submit Precourse, Pull Requests and resolving common errors?
                  <img
                    src={require('../../static/youtube.png')}
                    alt="Youtube Icon"
                    className="icon-small"
                    style={{ marginLeft: '1rem' }}
                    onClick={() =>
                      window.open(
                        `https://www.loom.com/share/1c134467fd55468c9ef5e752240915a8?sid=2dac3bbb-4031-40fe-b2a8-c143596a92dd`,
                        '_blank',
                      )
                    }
                  />
                </p>
                <p style={{ fontSize: '14px', margin: '8px' }}>
                  2. S30 never removes access for videos for alumni. Kindly log into youtube with
                  email <strong>{user.email}</strong> to access videos. If not working, try logging
                  into youtube with same email in incognito window. How to access list of recorded
                  videos in new dashboard?
                  <img
                    src={require('../../static/youtube.png')}
                    alt="Youtube Icon"
                    className="icon-small"
                    style={{ marginLeft: '1rem' }}
                    onClick={() =>
                      window.open(
                        `https://www.loom.com/share/84088494678a49f9b968bbe732ace2a1?sid=2d33f347-2ab0-45c6-9fdd-e581e28d1468`,
                        '_blank',
                      )
                    }
                  />
                </p>
                <p style={{ fontSize: '14px', margin: '8px' }}>
                  3. Interview Experiences and Questions Asked By Company
                  <img
                    src={require('../../static/youtube.png')}
                    alt="Youtube Icon"
                    className="icon-small"
                    style={{ marginLeft: '1rem' }}
                    onClick={() =>
                      window.open(
                        `https://www.loom.com/share/82dd14a456584b6bb5a8727334704986?sid=8b8f8d0d-1a67-4e7a-8a6c-76ad1363683a`,
                        '_blank',
                      )
                    }
                  />
                </p>
                <p style={{ fontSize: '14px', margin: '8px' }}>
                  4. How to get Referrals from S30?
                  <img
                    src={require('../../static/youtube.png')}
                    alt="Youtube Icon"
                    className="icon-small"
                    style={{ marginLeft: '1rem' }}
                    onClick={() =>
                      window.open(
                        `https://www.loom.com/share/1663c38e7121444aa7e36bde04341554?sid=6e281929-6674-4bb5-9a5f-59229f3f8064`,
                        '_blank',
                      )
                    }
                  />
                </p>
              </>
            )}
          </div>
          <div
            className="table"
            style={{ width: '45%', display: 'flex', alignItems: 'flex-start' }}
          >
            <div className="thead">
              <div className="columnAction">Class Rank</div>
              <div className="columnMedium">Name</div>
              <div className="columnAction">PRs completed</div>
            </div>
            {studentRankings.map(student => {
              return (
                <div className="tbody">
                  <div className="row">
                    <div className="columnAction">{student.rank}</div>
                    <div className="columnMedium">{student.studentName}</div>
                    <div className="columnAction">{student.totalPrs}</div>
                  </div>
                </div>
              );
            })}
            {/* <div className="row">
              <div className="columnAction">{studentRankings[2].studentName}</div>
              <div className="columnAction">{studentRankings[2].totalPrs}</div>
            </div> */}
          </div>
        </div>
        <b></b>
        <b></b>
        <b></b>
        <b></b>
        {isAlum && (
          <div>
            <h4 className="title">Request Alumni Interview</h4>
            <h5 style={{ fontSize: '14px' }}>
              First Read Alumni Mock interview
              <a
                style={{ cursor: 'pointer' }}
                onClick={() =>
                  window.open(
                    `https://docs.google.com/document/d/17wV3h90NIGaZYD6Mmg3K1_g7FnvvD0HkpFOtn7WSsh8`,
                    '_blank',
                  )
                }
              >
                <> Instructions</>
              </a>
            </h5>
            <button style={{ margin: 30 }} onClick={e => this.requestAlumnInterview(e, 1)}>
              Request Mock Interview
            </button>
            {isSystemDesignAuthorized && (
              <button style={{ margin: 30 }} onClick={e => this.requestAlumnInterview(e, 2)}>
                Request System Design Mock Interview
              </button>
            )}
          </div>
        )}
        {user.candidateId != undefined &&
        user.candidateId.assignedInterviewer != null &&
        user.candidateId.isScheduled ? (
          <>
            <h4 className="title">Interview Schedule</h4>
            <ul style={{ listStyle: 'none' }}>
              <li>
                {' '}
                Please make sure that your slack id is <b>{user.slackId}</b> so that interviewer can
                find you on slack.
              </li>
              <li>
                Time mentioned for interview is your system (Laptop/Mobile) time. It is <b>not</b>{' '}
                pacific time.
              </li>
            </ul>
            <div className="table">
              <div className="thead">
                <div className="columnLarge">Interviewer Name</div>
                <div className="columnMedium">Slack Id</div>
                <div className="columnMedium">Date</div>
                <div className="columnSmall">Start Time</div>
                <div className="columnSmall">End Time</div>
              </div>
              <Interview
                interviewerName={userId.firstName + ' ' + userId.lastName}
                interviewerSlackId={userId.slackId}
                date={moment(timeslot.startTime)
                  .format('ddd MMM D, YYYY')
                  .toString()}
                startTime={moment(timeslot.startTime)
                  .format('hh : mm A')
                  .toString()}
                endTime={moment(timeslot.endTime)
                  .format('hh : mm A')
                  .toString()}
              />
            </div>
          </>
        ) : (
          <>
            {!isAlum && (
              <>
                {isBlackListed ? (
                  <>
                    <h4 className="title">Upcoming Interview</h4>
                    <>
                      Your are currently in blacklist for a no-show in an interview. Please ping
                      Jaspinder on slack to remove.
                    </>
                  </>
                ) : (
                  <>
                    {isAvailableForInterview ? (
                      <div style={{ marginTop: '5rem' }}>
                        <h4 className="title">Mark Unavailabile for Weekend Mock Interview</h4>
                        <button
                          type="submit"
                          onClick={e => {
                            e.preventDefault();
                            this.markAvailability(_id, { isAvailableForInterview: false });
                          }}
                          // onClick={e => {
                          //   e.preventDefault();
                          //   props.updateCandidate(candidates, {
                          //     id,
                          //     isAvailableForInterview: false,
                          //   });
                          // }}
                          style={{ marginBottom: '1rem', backgroundColor: 'red' }}
                        >
                          <b>Not Available</b>
                        </button>
                      </div>
                    ) : (
                      <div style={{ marginTop: '5rem' }}>
                        <h4 className="title">Mark Availabile for Upcoming Interview</h4>
                        <button
                          type="submit"
                          onClick={e => {
                            e.preventDefault();
                            this.markAvailability(_id, { isAvailableForInterview: true });
                          }}
                          // onClick={e => {
                          //   e.preventDefault();

                          //   props.updateCandidate(candidates, {
                          //     id,
                          //     isAvailableForInterview: true,
                          //   });
                          // }}
                          style={{ marginBottom: '1rem' }}
                        >
                          <b>Available</b>
                        </button>
                      </div>
                    )}
                  </>
                )}
              </>
            )}
          </>
        )}
        <h4 style={{ marginTop: '3rem' }}>
          Right mindset for Tech Interviews - O(1) Book
          {billingLocation == 1 ? (
            <img
              src={require('../../static/amazon.png')}
              alt="Youtube Icon"
              className="icon-small"
              style={{ marginLeft: '1rem' }}
              onClick={() => window.open(`https://www.amazon.com/dp/B0C9SC74SY`, '_blank')}
            />
          ) : (
            <img
              src={require('../../static/amazon.png')}
              alt="Amazon Icon"
              className="icon-small"
              style={{ marginLeft: '1rem' }}
              onClick={() => window.open(`https://www.amazon.in/dp/B0CFCKLKZ3`, '_blank')}
            />
          )}
        </h4>
      </div>
    );
  }
}
const mapStateToProps = state => ({
  user: state.authState.user,
  candidate: state.authState.user.candidateId,
});

export default withStyles(styles)(connect(mapStateToProps)(CandidateDashboard));
