//@flow
import React from 'react';

import './Features.css';

const features = [
  {
    header: 'Classes + Mock Interviews',
    text:
      'Solve ~250 algorithm and data structure problems in 17 weeks live classes taken by experienced instructors. Weekly 1:1 mock interviews',
  },
  {
    header: 'Career Resources',
    text:
      'Recruitment support including networking with alumni, resume and linkedIn profile preparation.',
  },
  {
    header: 'System Design',
    text: 'System design videos by experienced instructors.',
  },
];

const Features = () => (
  <div>
    <div className="Features">
      <h3>Why S30?</h3>
      <div className="Features__wrapper">
        {features.map((feature, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <div key={index} className="Feature__container">
            <div className="Feature__container__content">
              <h4>{feature.header}</h4>
              <p style={{ textAlign: 'center', lineHeight: 1.8, padding: '1rem' }}>
                {feature.text}
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  </div>
);

export default Features;
